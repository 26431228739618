
            @import 'src/styles/mixins.scss';
        
.mainWrapper {
    position: relative;
    overflow: hidden;
    &:before {
        display: block;
        content: "";
        width: 991px;
        height: 1272px;
        background: #e6f7f8;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-34%, 49%) rotate(-50deg);
        @media screen and (max-width: 1023px) {
            transform: translate(-34%, 77%) rotate(-50deg);
        }
    }
}
