$businessphoneprices-go-go-green: #008a7b;
$businessphoneprices-green: #5bc1a4;

$comparepeorates-footer-text-color: #000;
$comparepeorates-footer-separator-color: #006336;
$comparepeorates-offer-call-number-color: #006336;
$comparepeorates-why-us-brief-color: #838282;
$comparepeorates-default-button-color: #00b2b8;
$comparepeorates-default-button-hover-color: #04a5aa;
$comparepeorates-default-radio-selected-bgcolor: #e6f7f8;
$comparepeorates-default-radio-text-color: #000;
$comparepeorates-default-progress-color: #838282;
$comparepeorates-default-progress-bgcolor: #f0f0f0;
$comparepeorates-default-progress-buble-text-color: #013d21;

:export {
    green: $businessphoneprices-green;
    whyUsBriefColor: $comparepeorates-why-us-brief-color;
    offerCallNumber: $comparepeorates-offer-call-number-color;
    defaultButtonColor: $comparepeorates-default-button-color;
    defaultProgressColor: $comparepeorates-default-progress-color;
}
